// -----------------------------------------------------------------------------
// Table components
// -----------------------------------------------------------------------------

up.compiler('#js-table-check-all', (element) => {
  const $element = $(element)

  $element.on('change', () => {
    if ($element.is(':checked')) {
      $element.parents('table').find('tbody input[type="checkbox"]').prop('checked', true).trigger('change');
    } else {
      $element.parents('table').find('tbody input[type="checkbox"]').prop('checked', false).trigger('change');
    }
  })

  return () => $element.off('change')
})

up.compiler('.js-row-checkbox', (element) => {
  const $element = $(element)
  const $allCheckboxes = $('.js-row-checkbox')
  const $selectableActions = $('.js-batch-action')
  const $batchActions = $('#js-table-batch')
  const $parentRow = $element.parents('tr')

  $element.on('change', () => {
    if ($element.is(':checked')) {
      $parentRow.addClass('-selected').removeClass('-focus')
    } else {
      $parentRow.removeClass('-selected')
      $('#js-table-check-all').prop('checked', false)
    }

    let $selection = $allCheckboxes.filter(':checked')
    let numChecked = $selection.length
    $batchActions.removeClass('-single').removeClass('-multiple')
    toggleActions($selection)

    if (numChecked === 1) {
      $batchActions.addClass('-single')
    } else if (numChecked > 1) {
      $batchActions.addClass('-multiple')
      $('#js-table-selection-count').text(numChecked)
    }
  })

  $element.on('focusin', () => {
    $parentRow.addClass('-focus')
    $element.on('keydown', (event) => {
      if (event.which === 40) { // arrow down key
        $parentRow.next().find('.js-row-checkbox').focus()
      } else if (event.which === 38) { // arrow up key
        $parentRow.prev().find('.js-row-checkbox').focus()
      }
    })
  }).on('focusout', () => {
    $parentRow.removeClass('-focus')
  })

  const toggleActions = (selection) => {
    $selectableActions.parent().show()

    $selectableActions.each((idx, element) => {
      let actionName = $(element).attr('name')

      if (selection.filter(`[data-action-${actionName}="true"]`).length !== selection.length) {
        $(element).parent().hide()
      }
    })
  }

  return () => $element.off('change').off('keydown').off('focusin').off('focusout')
})

up.compiler('tr .btn', (element) => {
  const $element = $(element)
  const $parentRow = $element.parents('tr')

  $element.on('focusin', () => {
    $parentRow.addClass('-focus')
  }).on('focusout', () => {
    $parentRow.removeClass('-focus')
  })

  return () => $element.off('focusin').off('focusout')
})

up.compiler('tr', (element) => {
  const $element = $(element)

  $element.on('focusin', () => {
    $element.addClass('-focus')
  }).on('focusout', () => {
    $element.removeClass('-focus')
  })

  return () => $element.off('focusin').off('focusout')
})
