import Rails from './rails-ujs'
import sweetAlert from 'sweetalert2/dist/sweetalert2'

const confirmed = (element, result) => {
    if (result.isConfirmed) {
        // User clicked confirm button
        element.removeAttribute('data-confirm-swal')
        element.click()
    }
}

// Display the confirmation dialog
const showConfirmationDialog = (element) => {
    const message = element.getAttribute('data-confirm-swal')
    const text = element.getAttribute('data-confirm-text')
    const yes = element.getAttribute('data-confirm-yes')
    const no = element.getAttribute('data-confirm-no')
    const color = element.getAttribute('data-confirm-color')

    sweetAlert.fire({
        title: message || 'Sind Sie sicher?',
        html: text || '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: yes || 'Ja',
        cancelButtonText: no || 'Abbrechen',
        confirmButtonColor: color || '#DE1C22',
        scrollbarPadding: false
    }).then(result => confirmed(element, result))
}

const allowAction = (element) => {
    if (element.getAttribute('data-confirm-swal') === null) {
        return true
    }

    showConfirmationDialog(element)
    return false
}

function handleConfirm(element) {
    if (!allowAction(this)) {
        Rails.stopEverything(element)
    }
}

Rails.delegate(document, '[data-confirm-swal]', 'click', handleConfirm)
